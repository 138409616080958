<template lang="">
  <Card>
    <template #header> Tapu Bilgileri </template>
    <div class="row">
      <!-- emlak kodu -->
      <div class="col-md-6">
        <div class="form-group">
          <label for="property_code">Emlak Kodu</label>
          <input
            type="text"
            class="form-control"
            id="property_code"
            name="property_code"
            v-model="property_code"
          />
        </div>
      </div>
      <!-- il -->
      <div class="col-md-6 col-12">
        <div class="form-group">
          <MultiSelect
            :key="counties.length"
            v-model="providence"
            :options="counties"
            :multiple="false"
            label="İl"
            placeholder="İl Seçiniz"
            id="il"
          />
        </div>
      </div>
      <!-- ilçe -->
      <div class="col-6">
        <div class="form-group">
          <MultiSelect
            :key="districts.length"
            v-model="district"
            :options="districts"
            :multiple="false"
            label="İlçe"
            placeholder="İlçe Seçiniz"
            id="ilce"
          />
        </div>
      </div>
      <!-- mahalle -->
      <div class="col-6">
        <b-form-group class="add-style">
          <MultiSelect
            :key="neighbourhoods.length"
            v-model="neighbourhood"
            :options="neighbourhoods"
            :multiple="false"
            label="Mahalle"
            placeholder="Mahalle Seçiniz"
            id="mahalle"
          />
        </b-form-group>
      </div>
      <!-- cadde -->
      <div class="col-md-6">
        <div class="form-group">
          <label for="avenue">Cadde</label>
          <input
            type="text"
            class="form-control"
            name="avenue"
            id="avenue"
            v-model="avenue"
          />
        </div>
      </div>
      <!-- sokak -->
      <div class="col-md-6">
        <div class="form-group">
          <label for="street">Sokak</label>
          <input
            type="text"
            class="form-control"
            name="street"
            id="street"
            v-model="street"
          />
        </div>
      </div>
      <!-- !!!!!! -->
      <!-- Dış Kapı Numarası -->
      <div class="col-md-6">
        <div class="form-group">
          <label for="">Dış Kapı Numarası</label>
          <input type="text" class="form-control" v-model="building_no" />
        </div>
      </div>
      <!-- iç kapı numarası -->
      <div class="col-md-6">
        <div class="form-group">
          <label for="">İç Kapı Numarası</label>
          <input type="text" class="form-control" v-model="in_door_no" />
        </div>
      </div>
      <!-- Mahal Alan -->
      <div class="col-md-3">
        <div class="form-group">
          <label for="living_area_m2">Mahal Alanı</label>
          <input
            type="text"
            class="form-control"
            name="living_area_m2"
            id="living_area_m2"
            v-model="living_area_m2"
          />
        </div>
      </div>
      <!-- Yasal Alan -->
      <div class="col-md-3">
        <div class="form-group">
          <label for="legal_area_m2">Yasal Alan</label>
          <input
            type="text"
            class="form-control"
            name="legal_area_m2"
            id="legal_area_m2"
            v-model="legal_area_m2"
          />
        </div>
      </div>
      <!-- Yüz Ölçümü -->
      <div class="col-md-3">
        <div class="form-group">
          <label for="surface_area_m2">Yüz Ölçümü</label>
          <input
            type="text"
            class="form-control"
            name="surface_area_m2"
            id="surface_area_m2"
            v-model="surface_area_m2"
          />
        </div>
      </div>
      <!-- pafta -->
      <div class="col-md-3">
        <div class="form-group">
          <label for="map_plan">Pafta</label>
          <input
            type="text"
            class="form-control"
            name="map_plan"
            id="map_plan"
            v-model="map_plan"
          />
        </div>
      </div>
      <!-- ada -->
      <div class="col-md-3">
        <div class="form-group">
          <label for="island">Ada</label>
          <input
            type="text"
            class="form-control"
            name="island"
            id="island"
            v-model="island"
          />
        </div>
      </div>
      <!-- parsel -->
      <div class="col-md-3">
        <div class="form-group">
          <label for="parcel">Parsel </label>
          <input
            type="text"
            class="form-control"
            name="parcel"
            id="parcel"
            v-model="parcel"
          />
        </div>
      </div>
      <!-- eski ada -->
      <div class="col-md-3">
        <div class="form-group">
          <label for="old_island">Ada (Eski)</label>
          <input
            type="text"
            class="form-control"
            name="old_island"
            id="old_island"
            v-model="old_island"
          />
        </div>
      </div>
      <!-- eski parsel -->
      <div class="col-md-3">
        <div class="form-group">
          <label for="old_parcel">Parsel (Eski)</label>
          <input
            type="text"
            class="form-control"
            name="old_parcel"
            id="old_parcel"
            v-model="old_parcel"
          />
        </div>
      </div>
      <!-- tapu türü -->
      <div class="col-md-12">
        <div class="form-group">
          <label for="real_type">Tapu Türü</label>
          <select
            name="real_type"
            id="real_type"
            class="form-control"
            v-model="real_type"
          >
            <option value="" selected>Seçiniz</option>
            <option value="1">Tapu Yok</option>
            <option value="2">Kat irtifakı</option>
            <option value="3">Cins Tashili Yapı</option>
          </select>
        </div>
      </div>
      <!-- imar lejant -->
      <div class="col-md-12">
        <div class="form-group">
          <label for="zoning_legend_function">İmar Lejant Fonksiyonu</label>
          <select
            name="zoning_legend_function"
            id="zoning_legend_function"
            class="form-control"
            v-model="zoning_legend_function"
          >
            <option value="" selected>Seçiniz</option>
            <option value="1">Konut</option>
            <option value="2">Ticari</option>
            <option value="3">Konut ve Ticari</option>
            <option value="4">Turizm</option>
          </select>
        </div>
      </div>
      <!-- fiili kullanım  -->
      <div class="col-6">
        <div class="from-group">
          <MultiSelect
            v-model="actual_use_nature_id"
            name="actual_use_nature_id"
            :options="purposes"
            :multiple="false"
            label="Filli Kullanım Niteliği"
            placeholder="Fiili Kullanım Niteliği Seçiniz"
            id="actual_use_nature_id"
          />
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <MultiSelect
            v-model="actual_intended_use_id"
            name="actual_intended_use_id"
            :options="usages"
            :multiple="false"
            label="Filli Kullanım Amacı"
            placeholder="Fiili Kullanım Amacı Seçiniz"
            id="usage"
          />
        </div>
      </div>
      <!-- bağımsız blok -->
      <div class="col-md-4">
        <div class="form-group">
          <label for="independent_sector_block">Bağımsız Bölüm Blok</label>
          <input
            type="text"
            class="form-control"
            name="independent_sector_block"
            id="independent_sector_block"
            v-model="independent_sector_block"
          />
        </div>
      </div>
      <!-- bağımsız giriş -->
      <div class="col-md-4">
        <div class="form-group">
          <label for="independent_sector_entry">Bağımsız Bölüm Giriş</label>
          <input
            type="text"
            class="form-control"
            name="independent_sector_entry"
            id="independent_sector_entry"
            v-model="independent_sector_entry"
          />
        </div>
      </div>
      <!-- bağımsız bölüm no -->
      <div class="col-md-4">
        <div class="form-group">
          <label for="independent_sector_no">Bağımsız Bölüm No</label>
          <input
            type="text"
            class="form-control"
            name="independent_sector_no"
            id="independent_sector_no"
            v-model="independent_sector_no"
          />
        </div>
      </div>
      <!-- bağımsız tapudaki nitelik -->
      <div class="col-md-6">
        <div class="form-group">
          <label for="bbtn">Bağımsız Bölümün Tapudaki Niteliği</label>
          <input
            type="text"
            class="form-control"
            name="bbtn"
            id="bbtn"
            v-model="bbtn"
          />
        </div>
      </div>
      <!-- taşınmaz id -->
      <div class="col-md-3">
        <div class="form-group">
          <label for="custom_real_id">Taşınmaz ID *</label>
          <input
            type="text"
            class="form-control"
            name="custom_real_id"
            id="custom_real_id"
            v-model="custom_real_id"
          />
        </div>
      </div>
      <!-- uavt kodu -->
      <div class="col-md-3">
        <div class="form-group">
          <label for="uavt_code">Uavt Kodu</label>
          <input
            type="text"
            class="form-control"
            name="uavt_code"
            id="uavt_code"
            v-model="uavt_code"
          />
        </div>
      </div>
      <!-- imar planı içinde -->
      <div class="col-md-6">
        <div class="form-group">
          <label for="in_development_plan">İmar Planı İçinde Mi? *</label>
          <select
            name="in_development_plan"
            id="in_development_plan"
            class="form-control"
            v-model="in_development_plan"
          >
            <option value="" selected>Seçiniz</option>
            <option value="1">Evet</option>
            <option value="0">Hayır</option>
          </select>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label for="in_law_no_3194">3194 Sayılı İmar Kanunu'na Eklenen Geçici 16'ıncı Madde Kapsamında Çevre, Şehircilik ve İklim Değişikliği Başkanlığına Başvuruda Bulunma Bilgisi *</label>
          <select
            name="in_law_no_3194"
            id="in_law_no_3194"
            class="form-control"
            v-model="in_law_no_3194"
          >
            <option value="" selected>Seçiniz</option>
            <option value="1">Evet</option>
            <option value="0">Hayır</option>
          </select>
        </div>
      </div>
      <!-- yevmiye no -->
      <div class="col-md-6">
        <div class="form-group">
          <label for="wage_no">Yevmiye No *</label>
          <input
            type="text"
            class="form-control"
            name="wage_no"
            id="wage_no"
            v-model="wage_no"
          />
        </div>
      </div>
    </div>
    <!-- Malikler -->
    <h4 class="mt-5 mx-3 mb-5">Malikler</h4>
    <div class="row" v-for="i in malikCount" :id="'malik-' + i" :key="i">
        <!-- trash icon -->
        <div class="col-12 d-flex">
          <div
            class="col-1"
            style="
              max-width: 8.33333333%;
              justify-content: center;
              align-items: center;
              display: flex;
            "
          >
            <b-button variant="light" @click="deleteRow(i)">
              <i class="flaticon2-trash p-0"></i>
            </b-button>
          </div>
          <!-- hissedar -->
          <div class="col">
            <div class="from-group">
              <MultiSelect
                v-model="owners[i - 1].actualuse_attribute"
                :options="sellers"
                :multiple="false"
                label="Hissedar"
                placeholder="Hissedar Seçiniz"
                :id="'hissedar-' + i"
              />
            </div>
          </div>
          <!-- hisse pay -->
          <div class="col">
            <b-form-group class="add-style">
              <label for="pay">Hisse (Pay) </label>
              <b-form-input v-model="owners[i - 1].share" id="pay" required />
            </b-form-group>
          </div>
          <!-- hisse pay -->
          <div class="col">
            <b-form-group class="add-style">
              <label for="payda">Hisse (Payda) </label>
              <b-form-input
                v-model="owners[i - 1].denominator"
                id="payda"
                required
              />
            </b-form-group>
          </div>
        </div>
      </div>

      <div class="col-3">
        <b-button variant="light" @click="malikEkle">
          Malik Ekle
          <i class="flaticon2-plus p-0 ml-2"></i>
        </b-button>
      </div>

    <h4 class="mt-5 mx-3 mb-5">Özellikler</h4>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label for="">Bina Kat Sayısı *</label>
          <input type="text" class="form-control" value="1" v-model="building_floor_count"/>
        </div>
      </div>
      <!-- bulunduğu kat -->
      <div class="col-md-3">
        <div class="form-group">
          <label for="real_floor_location">Bulunduğu Kat *</label>
          <select
            name="real_floor_location"
            id="real_floor_location"
            class="form-control"
            v-model="real_floor_location"
          >
            <option value="" selected>Seçiniz</option>
            <option value="1">Zemin</option>
            <option value="2">1. Kat</option>
            <option value="3">2. Kat</option>
          </select>
        </div>
      </div>
      <!-- yapı cinsi -->
      <div class="col-md-3">
        <div class="form-group">
          <label for="building_type">Yapı Cins *</label>
          <select
            name="building_type"
            id="building_type"
            class="form-control"
            v-model="building_type"
          >
            <option value="" selected>Seçiniz</option>
            <option value="1">Karkas</option>
            <option value="2">Ahşap</option>
            <option value="3">Çelik</option>
          </select>
        </div>
      </div>
      <!-- bina inşa yılı -->
      <div class="col-md-3">
        <div class="form-group">
          <label for="building_construction_year">Bina İnşa Yılı *</label>
          <input
            type="text"
            class="form-control"
            value="2023"
            name="building_construction_year"
            id="building_construction_year"
            v-model="building_construction_year"
          />
        </div>
      </div>
      <!-- oda sayısı -->
      <div class="col-md-3">
        <div class="form-group">
          <label for="room_count">Oda Sayısı *</label>
          <input
            type="text"
            class="form-control"
            value="1"
            name="room_count"
            id="room_count"
            v-model="room_count"
          />
        </div>
      </div>
      <!-- salon sayısı -->
      <div class="col-md-3">
        <div class="form-group">
          <label for="living_room_count">Salon Sayısı</label>
          <input type="text" id="living_room_count" class="form-control" value="0" v-model="living_room_count" />
        </div>
      </div>
      <!-- banyo sayısı -->
      <div class="col-md-3">
        <div class="form-group">
          <label for="bath_room_count">Banyo Sayısı</label>
          <input
            type="text"
            class="form-control"
            name="bath_room_count"
            id="bath_room_count"
            v-model="bath_room_count"
          />
        </div>
      </div>
      <!-- wc sayısı -->
      <div class="col-md-3">
        <div class="form-group">
          <label for="wc_count">Wc Sayısı</label>
          <input
            type="text"
            class="form-control"
            value="0"
            name="wc_count"
            id="wc_count"
            v-model="wc_count"
          />
        </div>
      </div>
      <!-- balkon sayısı -->
      <div class="col-md-4">
        <div class="form-group">
          <label for="balconies_count">Balkon Sayısı</label>
          <input
            type="text"
            class="form-control"
            value="0"
            name="balconies_count"
            id="balconies_count"
            v-model="balconies_count"
          />
        </div>
      </div>
      <!-- kullanım durumu -->
      <div class="col-md-4">
        <div class="form-group">
          <label for="using_status">Kullanım Durumu</label>
          <select
            name="using_status"
            id="using_status"
            class="form-control"
            v-model="using_status"
          >
            <option value="" selected>Seçiniz</option>
            <option value="1">Diğer</option>
            <option value="2">Boş</option>
            <option value="3">Kiracı</option>
          </select>
        </div>
      </div>
      <!-- krediye uygun mu -->
      <div class="col-md-4">
        <div class="form-group">
          <label for="suitable_for_credit">Krediye Uygun Mu *</label>
          <select
            name="suitable_for_credit"
            id="suitable_for_credit"
            class="form-control"
            v-model="suitable_for_credit"
          >
            <option value="" selected>Seçiniz</option>
            <option value="0">Hayır</option>
            <option value="1">Evet</option>
            <option value="2">Diğer</option>
          </select>
        </div>
      </div>
      <!-- asansor -->
      <div class="col-md-3">
        <div class="form-group">
          <label for="has_building_elevator">Asansor *</label>
          <select
            name="has_building_elevator"
            id="has_building_elevator"
            class="form-control"
            v-model="has_building_elevator"
          >
            <option value="" selected>Seçiniz</option>
            <option value="0">Yok</option>
            <option value="1">Var</option>
          </select>
        </div>
      </div>
      <!-- ısıtma sistemi -->
      <div class="col-md-3">
        <div class="form-group">
          <label for="heating_system">Isıtma Sistemi *</label>
          <select
            name="heating_system"
            id="heating_system"
            class="form-control"
            v-model="heating_system"
          >
            <option value="" selected>Seçiniz</option>
            <option value="1">Soba</option>
            <option value="2">Doğalgaz</option>
            <option value="3">Diğer</option>
          </select>
        </div>
      </div>
      <!-- yapı durumu -->
      <div class="col-md-3">
        <div class="form-group">
          <label for="building_status">Yapı Durumu *</label>
          <select
            name="building_status"
            id="building_status"
            class="form-control"
            v-model="building_status"
          >
            <option value="" selected>Seçiniz</option>
            <option value="1">İkinci El</option>
            <option value="2">Yeni</option>
          </select>
        </div>
      </div>
      <!-- aidat -->
      <div class="col-md-3">
        <div class="form-group">
          <label for="aidat_total">Aidat Tutarı</label>
          <input
            type="text"
            class="form-control"
            name="aidat_total"
            id="aidat_total"
            v-model="aidat_total"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <h4 class="mt-5 mx-3 mb-5">Diğer Özellikler</h4>
      <div class="col-12 mt-5">
        <div  v-if="featuresList" class="feature-checkbox">
          <b-form-group v-for="(feature,key) in featuresList" :label="feature[0].category_name"  :key="key">
            <b-form-checkbox-group
              id="checkbox-group-2"
              name="flavour-2"
            >
              <b-form-checkbox v-for="(findex,key) in feature" :key="key" :value="findex._id.$oid"  size="xl">{{findex.feature_name}}</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </div>
      </div>
    </div>

    <div class="row">
      <h4 class="w-100 mt-5 mx-3 mb-5">Harita Koordinatları</h4>
      <!-- enlem -->
      <div class="col-md-6">
        <div class="form-group">
          <label for="map_lat">Enlem</label>
          <input
            type="text"
            class="form-control"
            name="map_lat"
            id="map_lat"
            v-model="map_lat"
          />
        </div>
      </div>
      <!-- boylam -->
      <div class="col-md-6">
        <div class="form-group">
          <label for="map_long">Boylam</label>
          <input
            type="text"
            class="form-control"
            name="map_long"
            id="map_long"
            v-model="map_long"
          />
        </div>
      </div>
    </div>
    <div class="buttons">
      <b-form-group>
        <b-button variant="success" class="ml-4" @click="save">Kaydet</b-button>
      </b-form-group>
    </div>
    
  </Card>
</template>
<script>
import {
  COUNTIES,
  DISTRICTS,
  NEIGHBOURHOODS,
} from "@/core/services/store/counties.module.js";
import { SELLERS } from "@/core/services/store/seller/seller.module.js";
import { PURPOSE, USAGE } from "@/core/services/store/usage.module";
import { FEATURES } from "@/core/services/store/features/features.module";

import { mapGetters } from "vuex";
import moment from "moment";
moment.locale("tr");

export default {
  data() {
    return {
      loading: false,

      districts: [],
      neighbourhoods: [],
      providence: "",
      district: "",
      neighbourhood: "",
      counties: [],
      malikCount: 1,
      purposes: [],
      usages: [],

      property_code: "",
      city_id: "",
      district_id: "",
      neighbourhood_id: "",
      avenue: "",
      street: "",
      island: "",
      parcel: "",
      actual_intended_use_id: "",
      actual_use_nature_id: "",
      custom_real_id: "",
      in_development_plan: "",
      wage_no: "",
      in_law_no_3194: "",
      building_floor_count: "",
      real_floor_location: "",
      building_type: "",
      building_construction_year: "",
      room_count: "",
      living_room_count: "",
      bath_room_count: "",
      wc_count: "",
      balconies_count: "",
      using_status: "",
      suitable_for_credit: "",
      has_building_elevator: "",
      heating_system: "",
      building_status: "",
      aidat_total: "",
      building_no: "",
      in_door_no: "",
      living_area_m2: "",
      legal_area_m2: "",
      surface_area_m2: "",
      map_plan: "",
      old_island: "",
      old_parcel: "",
      real_type: "",
      zoning_legend_function: "",
      independent_sector_block: "",
      independent_sector_entry: "",
      independent_sector_no: "",
      bbtn: "",
      uavt_code: "",
      energy_class: "",
      building_registry_no: "",
      building_registry_application_no: "",
      real_edit_date: "",
      map_lat: "",
      map_long: "",
      featuresList:[],
      malikCount: 1,
      sellers: [],
      owners:[
      {
          actualuse_attribute: "",
          share: "",
          denominator: "",
        },
      ]

    };
  },
  created() {
    let payload = { myUser: this.myUser, query: "" };
    this.$store.dispatch(COUNTIES);
    this.$store.dispatch(PURPOSE, this.myUser);
    this.$store.dispatch(FEATURES, payload);
    this.$store.dispatch(SELLERS, payload);
    if(this.$route.query.update_id){
      this.getReal()
    }

  },
  methods: {
    save() {
      const ownersArray = this.owners.map((item) => {
        return {
          share: item.share,
          denominator: item.denominator,
          actualuse_attribute: item.actualuse_attribute.value,
        };
      });

      let data = {
        property_code: this.property_code,
        city_id: this.providence.value,
        district_id: this.district.value,
        neighbourhood_id: this.neighbourhood.value,
        avenue: this.avenue,
        street: this.street,
        island: this.island,
        parcel: this.parcel,
        actual_intended_use_id: this.actual_intended_use_id.value,
        actual_use_nature_id: this.actual_use_nature_id.value,
        custom_real_id: this.custom_real_id,
        in_development_plan: this.in_development_plan,
        wage_no: this.wage_no,
        in_law_no_3194: this.in_law_no_3194,
        building_floor_count: this.building_floor_count,
        real_floor_location: this.real_floor_location,
        building_type: this.building_type,
        building_construction_year: this.building_construction_year,
        room_count: this.room_count,
        living_room_count: this.living_room_count,
        bath_room_count: this.bath_room_count,
        wc_count: this.wc_count,
        balconies_count: this.balconies_count,
        using_status: this.using_status,
        suitable_for_credit: this.suitable_for_credit,
        has_building_elevator: this.has_building_elevator,
        heating_system: this.heating_system,
        building_status: this.building_status,
        aidat_total: this.aidat_total,
        building_no: this.building_no,
        in_door_no: this.in_door_no,
        living_area_m2: this.living_area_m2,
        legal_area_m2: this.legal_area_m2,
        surface_area_m2: this.surface_area_m2,
        map_plan: this.map_plan,
        old_island: this.old_island,
        old_parcel: this.old_parcel,
        real_type: this.real_type,
        zoning_legend_function: this.zoning_legend_function,
        independent_sector_block: this.independent_sector_block,
        independent_sector_entry: this.independent_sector_entry,
        independent_sector_no: this.independent_sector_no,
        bbtn: this.bbtn,
        uavt_code: this.uavt_code,
        energy_class: this.energy_class,
        building_registry_no: this.building_registry_no,
        building_registry_application_no: this.building_registry_application_no,
        real_edit_date: this.real_edit_date,
        map_lat: this.map_lat,
        map_long: this.map_long,
        property_id : this.$route.params.id,
        user_token : this.myUser
      };
      const formData = new FormData()
      for (const key in data) {
        formData.append(key, data[key]);
      }

      ownersArray.forEach((owner, index) => {
        formData.append(`owners[${index}][share]`, owner.share);
        formData.append(`owners[${index}][denominator]`, owner.denominator);
        formData.append(`owners[${index}][actualuse_attribute]`, owner.actualuse_attribute);
      });

      this.$ApiService.post("property/real", formData).then((res) => {
        this.$generateNotification(
          this,
          "success",
          "Başarılı bir şekilde kaydedildi."
        );
      }).catch(err=>{
        this.$generateNotification(
          this,
          "error",
          "kayıt eklenirken bir hata ile karşılaşıldı."
        );
      });
    },
    onProvinceChange() {
      if (this.providence) {
        this.$store
          .dispatch(DISTRICTS, this.providence.value)
          .then((districts) => {
            const newDistricts = this.setDistrictsSelectValues(districts);
            this.districts = newDistricts;
          });
      } else {
        this.districts = [];
      }
    },
    deleteRow(i) {
      let row = document.getElementById("malik-" + i);
      row.remove();
      this.owners.splice(i - 1, 1);
    },
    malikEkle() {
      this.malikCount += 1;
      this.owners.push({
        actualuse_attribute: "",
        share: "",
        denominator: "",
      });
    },
    onDistrictChange() {
      if (this.district) {
        const payload = {
          il_id: this.providence.value,
          ilce_id: this.district.value,
        };
        this.$store.dispatch(NEIGHBOURHOODS, payload).then((street) => {
          const newNeighbourhoods = this.setNeighbourhoodsSelectValues(street);
          this.neighbourhoods = newNeighbourhoods;
        });
      } else {
        this.newNeighbourhoods = [];
      }
    },
    setDistrictsSelectValues(districts) {
      const setDistrictsSelect = districts.map((index, key) => {
        return { text: index.ilce_adi, value: index.ilce_id };
      });
      return setDistrictsSelect;
    },
    setNeighbourhoodsSelectValues(neighbourhoods) {
      const setNeighbourhoodsSelect = neighbourhoods.map((index, key) => {
        return { value: index.mahalle_id, text: index.mahalle_adi };
      });
      return setNeighbourhoodsSelect;
    },
    setCountiesSelectValues(county) {
      if (county?.length > 0) {
        const newCounties = county.map((index, key) => {
          return { text: index.il_adi, value: index.il_id };
        });
        this.counties = newCounties;
      }

      this.countiesLoading = false;
    },
    setPurposeSelectValue(purposes) {
      const setPurposesSelect = purposes.map((index, key) => {
        return { value: index._id.$oid, text: index.name };
      });
      return setPurposesSelect;
    },
    onPurposeChange() {
      if (this.actual_use_nature_id) {
        const payload = {
          user_token: this.myUser,
          purpose_id: this.actual_use_nature_id.value,
        };
        this.$store.dispatch(USAGE, payload);
      }
    },
    setUsageSelectValue(usage) {
      const setUsagesSelect = usage.map((index, key) => {
        return { value: index._id.$oid, text: index.name };
      });
      return setUsagesSelect;
    },
    setSellerSelectValues(sellers) {
      const setSellerSelect = sellers.map((index, key) => {
        return { value: index._id.$oid, text: index.title };
      });
      return setSellerSelect;
    },
    getReal(){
      let query = `property/real?user_token=${this.myUser}&real_id=${
        this.$route.query.update_id
      }`;

      this.$dbFunctions.get(query).then((res)=>{
        let data = res.response.data
        this.property_code= data.property_code,
        this.avenue= data.avenue,
        this.street= data.street,
        this.island= data.island,
        this.parcel= data.parcel,
        this.actual_intended_use_id= data.actual_intended_use_id.value,
        this.actual_use_nature_id= data.actual_use_nature_id.value,
        this.custom_real_id= data.custom_real_id,
        this.in_development_plan= data.in_development_plan,
        this.wage_no= data.wage_no,
        this.in_law_no_3194= data.in_law_no_3194,
        this.building_floor_count= data.building_floor_count,
        this.real_floor_location= data.real_floor_location,
        this.building_type= data.building_type,
        this.building_construction_year= data.building_construction_year,
        this.room_count= data.room_count,
        this.living_room_count= data.living_room_count,
        this.bath_room_count= data.bath_room_count,
        this.wc_count= data.wc_count,
        this.balconies_count= data.balconies_count,
        this.using_status= data.using_status,
        this.suitable_for_credit= data.suitable_for_credit,
        this.has_building_elevator= data.has_building_elevator,
        this.heating_system= data.heating_system,
        this.building_status= data.building_status,
        this.aidat_total= data.aidat_total,
        this.building_no= data.building_no,
        this.in_door_no= data.in_door_no,
        this.living_area_m2= data.living_area_m2,
        this.legal_area_m2= data.legal_area_m2,
        this.surface_area_m2= data.surface_area_m2,
        this.map_plan= data.map_plan,
        this.old_island= data.old_island,
        this.old_parcel= data.old_parcel,
        this.real_type= data.real_type,
        this.zoning_legend_function= data.zoning_legend_function,
        this.independent_sector_block= data.independent_sector_block,
        this.independent_sector_entry= data.independent_sector_entry,
        this.independent_sector_no= data.independent_sector_no,
        this.bbtn= data.bbtn,
        this.uavt_code= data.uavt_code,
        this.energy_class= data.energy_class,
        this.building_registry_no= data.building_registry_no,
        this.building_registry_application_no= data.building_registry_application_no,
        this.real_edit_date= data.real_edit_date,
        this.map_lat= data.map_lat,
        this.map_long= data.map_long

      })
    }
  },
  watch: {
    getCounties(newCounties) {
      if (newCounties.length > 0) {
        this.setCountiesSelectValues(newCounties);
      }
    },
    providence: "onProvinceChange",
    district: "onDistrictChange",
    getPurpose(newPurpose) {
      this.purposes = this.setPurposeSelectValue(newPurpose);
    },
    actual_use_nature_id: "onPurposeChange",
    getUsage(newUsage) {
      this.usages = this.setUsageSelectValue(newUsage);
    },
    getFeatures(newFeatures) {
      const kategorilereGoreAyrilmisVeriler = {};

      newFeatures.forEach((item) => {
        const categoryName = item.category_name;
        if (!kategorilereGoreAyrilmisVeriler[categoryName]) {
          kategorilereGoreAyrilmisVeriler[categoryName] = [];
        }
        kategorilereGoreAyrilmisVeriler[categoryName].push(item);
      });
      this.featuresList = kategorilereGoreAyrilmisVeriler
    },
    getSellers(newSellers) {
      this.sellers = this.setSellerSelectValues(newSellers);
    },

  },
  computed: {
    ...mapGetters([
      "getCounties",
      "getSellers",
      "myUser",
      "getPurpose",
      "getUsage",
      "getFeatures",
    ]),
  },
};
</script>
<style lang="scss" scoped>
.buttons {
  display: flex;
  justify-content: flex-end;
}
.saveButton {
  background-color: $dveb-success;
  padding: 8px 12px;
  border-radius: 10px;
  color: white;
}
.saveButton:hover {
  background-color: rgb(39, 117, 39);
  transition: 0.2s;
}
.addButton {
  background-color: $dveb-divider;
  padding: 8px 12px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
}
</style>
